*{
    margin: 0px;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header
{
    margin-top: 5rem;
    font-weight: 700;
    font-size: 30px;
    color: #194b74;
    margin-bottom: 1vw;
}

.header__text{
    font-weight: 700;
    color: #194b74;
}

.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.navbar a{
    
   margin: 1rem;
    display: inline-block;
    vertical-align: top;
    line-height: 40px;
    text-decoration: none;
    font-weight: 700;
    color: #194b74;
    font-size: 15px;
}

.cliente_container {
    height: 100%;
}