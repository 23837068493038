* {
  margin: 0;
  padding: 0;
}
.containerV {
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerVa label{
  margin-top: 5rem;
 
}
.empresas-margin{
  margin-top: 7rem !important;
}

.label-margin-nomina{
  justify-content: center;
  display: flex;
  text-align: center;
  margin-top: 5rem;
  align-items: center;
  
}

.label-margin-nomina label h1{
  color: #194B74;


}

.containerVa label{
  margin-top: 5rem;
 
}

.containerVa .sp{
  margin-top: -3rem;
  color: #194B74;
  text-align: center;
  font-size: 1.2rem;

}

.Npaso_1{
  border-bottom: 2.3px solid #fbc02a;
}

.navnomina{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color:   #194B74;
  font-weight: bold;
  
}

.containerVa select,
input {
background: #f6f6f6;
border-radius: 2px;
padding: 12px;
flex: 0 1 10%;
display: flex;
width: 250px;
justify-content: space-between;
align-items: center;
font-size: 16px;
outline-width: 0;
}
.containerV div {
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem;
}

@media (max-width: 800px) {
  .containerV {
    flex-direction: column;
  }
}

.containerV select,
input {
  background: #f6f6f6;
  border-radius: 2px;
  padding: 12px;
  flex: 0 1 10%;
  display: flex;
  width: 250px;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  outline-width: 0;
}

.ButtonV {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  flex-direction: column;
}

.invalidV {
  border: 1px solid red;
  
}


.containerV 
input {
  background: #f6f6f6;
  border-radius: 2px;
  padding: 12px;
  flex: 0 1 10%;
  display: flex;
  width: 250px;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  outline-width: 0;
}

.navbar1  .hg2, .hg3 , .hg4,.hg5{
  margin: 2rem 1rem;
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  color: #194b74;
  font-size: .8rem;
}

.navbar1 .hg1{
  margin: 2rem 1rem;
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  color: #194b74;
  font-size: bold;
}

.imagenes-modal{
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagenes-modal img{
  margin: 1rem;
}

.modal1-boton-cerrar {
  display: flex;
  align-items: center;
  justify-content: center;
}




