.uploadBoxes{
    margin-top: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
}

.navbar5  .hg15, .hg25 , .hg35,.hg45{
    margin: 2rem 1rem;
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    color: #194b74;
    font-size: .8rem;
  }
  
  .navbar5 .hg55{
    margin: 2rem 1rem;
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    color: #194b74;
    font-size: bold;
  }